<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title">
            <v-icon>layers</v-icon>
            Assign Guardian/User School
            <v-spacer></v-spacer>
          </v-card-title>

          <v-card flat>
            <v-card-title class="title">
              <v-flex xs4>
                <v-text-field
                  hint="Atleast 3 characters"
                  flat
                  color="primary"
                  class="pa-0"
                  label="Search By Name/Mobile/Email"
                  v-model="guardian"
                />
              </v-flex>
            </v-card-title>
          </v-card>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            footer-props.items-per-page-options="rowsPerPageItems"
            :options.sync="pagination"
            :server-items-length="form.items.meta.total"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ ++index }}</td>
                <td class="text-xs-left">
                  {{ item.name }} 
                  <v-chip
                        v-if="item.role"
                        class="ma-2"
                        label
                        small
                      >
                      <span style="color:#555;">{{ item.role }}</span>
                  </v-chip>
                  <div>
                    <span style="font-size: 11px">{{ item.email }} </span>
                    <br /><span style="font-size: 11px">{{
                      item.primary_mobile
                    }}</span>
                  </div>
                </td>
                <td class="text-xs-left">
                  {{ item.occupation === "null" ? "-" : item.occupation }}
                </td>
                <td class="text-xs-left">
                  <span
                    v-for="(x, key) in item.school.split(',')"
                    :key="key"
                    class="name"
                    style="margin-right: 5px;font-size: 10px"
                    >{{ x }}</span
                  >
                </td>
                <td class="text-xs-left">
                  <v-btn
                    small
                    icon
                    color="info"
                    @click="assign(item)"
                    class="pa-0 ma-1"
                  >
                    <v-icon small>edit</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            <template slot="no-data">
              <v-alert :value="true" color="error" icon="warning">
                No record found.
              </v-alert>
            </template>
          </v-data-table>
          <v-dialog v-model="form.dialog" persistent max-width="500px">
            <v-card>
              <v-card-title class="title primary white--text"
                >Assign School</v-card-title
              >
              <v-card-text>
                <v-form ref="form">
                  <v-container grid-list-md>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <span class="name">{{ guardianName }}</span>
                      </v-flex>
                      <v-flex>
                        <span
                          v-if="guardianEmail"
                          class="name"
                          style="margin-right: 5px"
                          >{{ guardianEmail }} </span
                        ><span v-if="guardianMobile" class="name">{{
                          guardianMobile
                        }}</span>
                      </v-flex>
                      <v-flex xs12 sm12>
                        <v-autocomplete
                          v-model="school_id"
                          :items="itemS"
                          :search-input.sync="searchS"
                          hide-no-data
                          item-text="Name"
                          item-value="id"
                          label="Search School"
                          placeholder="Start typing to Search"
                          @change="fetchRole()"
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex xs12 sm12 v-if="dispalyRole">
                        <v-select
                            :items="roles"
                            class="pa-0 pt-3"
                            label="Select Role"
                            outlined
                            dense
                            v-model="role"

                          />
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="warning"
                  outlined
                  flat
                  @click="(form.dialog = false), form.reset()"
                  >Cancel
                </v-btn>
                <v-btn color="success" outlined flat @click="save">OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Form from "@/library/Form";

const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();

export default {
  data: () => ({
    form: new Form(
      {
        name: "",
        description: "",
        company_id: "",
        parent_id: "",
      },
      "/api/user/guardian/search"
    ),
    search: null,
    isLoading: false,
    rowsPerPageItems: [5, 10, 25, 50, 75, 100],
    pagination: {
      rowsPerPage: 10,
    },
    school_id: "",
    guardian: "",
    entriesS: [],
    searchS: null,
    guardianName: "",
    guardianEmail: "",
    guardianMobile: "",
    guardianId: "",
    headers: [
      { text: "#", align: "left", sortable: false, value: "id", width: "20px" },
      { text: "Name", value: "name", align: "left" },
      { text: "Occupation", value: "occupation", align: "left" },
      { text: "School", value: "school", align: "left", width: "50%" },
      {
        text: "Actions",
        value: "action",
        sortable: false,
        align: "right",
        width: 120,
      },
    ],
    roles:[],
    dispalyRole:false,
  }),

  computed: {
    ...mapState(["batch"]),
    itemS() {
      return this.entriesS.map((entry) => {
        return { Name: entry.name, id: entry.id };
      });
    },
    itemG() {
      return this.entriesG.map((entry) => {
        return { Name: entry.name, id: entry.id };
      });
    },
  },

  watch: {
    pagination: function() {
      this.get();
    },
    batch: function(value) {
      this.get();
    },
    searchS(val) {
      if (!val) return;
      this.isLoading = true;
      this.$rest
        .get("/api/company?search=" + val)
        .then(({ data }) => {
          // this.entries = data.data
          this.entriesS = data.data.map((res) => {
            return { name: res.name, id: res.id };
          });
        })
        .catch((err) => {})
        .finally(() => (this.isLoading = false));
    },
    searchG(val) {
      if (!val) return;

      this.isLoading = true;
      this.$rest
        .get("api/user/guardian-search?search=" + val)
        .then(({ data }) => {
          // this.entries = data.data
          this.entriesG = data.data.map((res) => {
            return { name: res.name, id: res.id };
          });
        })
        .catch((err) => {})
        .finally(() => (this.isLoading = false));
    },
    guardian: function() {
      this.get();
    },
  },
  
  methods: {
    fetchRole(){
      if(this.dispalyRole){
        this.$rest.get('/api/role-company/'+this.school_id).then((res)=>{
          this.roles = res.data.roles
        })
      } 
    },
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function(key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
              );
          })
          .join("&") +
        "&search=" +
        this.guardian
      );
    },

    get(params) {
      if (this.guardian.length >= 3) {
        let query = [null, undefined].includes(params)
          ? this.queryString()
          : params;
        this.form.get(null, query+"&onlyguardian="+'false').then(({ data }) => {
          this.pagination.totalItems = data.meta.total;
        });
      }
    },

    save() {
      this.$rest
        .post("api/company-guardian", {
          company_id: this.school_id,
          user_id: this.guardianId,
          role_id:this.role
        })
        .then((data) => {
          this.form.dialog = false;
          this.$events.fire("notification", {
            message: data.message,
            status: "success",
          });
          this.get();
        });
    },
    assign(item) {
      this.dispalyRole = item.role !== 'Guardian' ? true:false
      this.form.dialog = true;
      this.guardianName = item.name;
      this.guardianEmail = item.email;
      this.guardianMobile = item.primary_mobile ? item.primary_mobile : "";
      this.guardianId = item.id;
    },
  },
};
</script>
<style lang="scss">
.name {
  background: #4caf50;
  color: white;
  border-radius: 10px;
  padding: 5px;
}
</style>
